import React from 'react'
import { css } from 'emotion'
import Colors from '../globals/colors'
import { modalClose } from '../globals/common'
import ReactPlayer from 'react-player'

const featureList = css({
  width: `25%`,
  marginRight: `0.5rem`,
  textAlign: `right`,
  '@media only screen and (orientation: portrait)': {
    width: `100%`,
  },
})

const featureVideo = css({
  width: `75%`,
  backgroundColor: Colors.purple,
  '@media only screen and (orientation: portrait)': {
    width: `100%`,
    order: -1,
  },
})

const featureVideoWrapper = css({
  position: `relative`,
  paddingTop: `56.25%`,
})

const featureItem = css({
  cursor: `pointer`,
  fontSize: `1rem`,
  fontVariationSettings: `'wght' 500, 'ital' 0, 'wdth' 70`,
  letterSpacing: `1px`,
  textTransform: `uppercase`,
  textAlign: `left`,
  color: Colors.black,
  fontStyle: `normal`,
  padding: `0.6rem 0.8rem`,
  lineHeight: `1.9`,
  marginBottom: `0.5rem`,
  backgroundColor: Colors.teal,
  '&:hover': {
    color: Colors.white,
    backgroundColor: Colors.magenta,
  },
})

const featureItemActive = css({
  color: Colors.white,
  backgroundColor: Colors.magenta,
})

const featureLabel = css({ textTransform: `uppercase` })

const featureDescription = css({
  textAlign: `left`,
  marginTop: `0.5rem`,
  marginBottom: `1rem`,
  color: Colors.white,
  fontSize: `1rem`,
  fontVariationSettings: `'wght' 500, 'ital' 0, 'wdth' 70`,
  fontStyle: `normal`,
  lineHeight: `1.5`,
  transition: `all 0.2s ease-in-out`,
})

const Features = ({
  array,
  url,
  playerRef,
  playing,
  clickHandler,
  closeHandler,
}) => {
  return (
    <React.Fragment>
      <div className={featureList}>
        {array.map(feature => {
          return (
            <React.Fragment key={feature.id}>
              <div
                onClick={() => clickHandler(feature.url, feature.description)}
                className={`${ featureItem } ${ url === feature.url &&
                  featureItemActive }`}
              >
                <div className={featureLabel}>
                  {url === feature.url ? '–' : '+'} {feature.label}
                </div>
              </div>
              {url === feature.url && (
                <div className={featureDescription}>{feature.description}</div>
              )}
            </React.Fragment>
          )
        })}
      </div>
      <div className={featureVideo}>
        <div className={modalClose} onClick={() => closeHandler()}>
          Close
        </div>
        <div className={featureVideoWrapper}>
          <ReactPlayer
            ref={playerRef}
            className={css({
              position: `absolute`,
              top: 0,
              left: 0,
            })}
            url={url}
            width="100%"
            height="100%"
            volume={0.4}
            playing={playing}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Features
