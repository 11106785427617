const colors = {
  black: `#111111`,
  white: `#ffffff`,
  gold: `#fffea1`,
  teal: `#6ce8ec`,
  red: `#eb0022`,
  magenta: `#c9269a`,
  purple: `#17002c`,
  orange: `#ef7b41`,
  bone: `#f2fcd2`,
  blue: `#5b00ff`
}

export default colors
