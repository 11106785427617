import { css } from 'emotion'
import Colors from '../globals/colors'

export const link = css({
  cursor: `pointer`,
  '&:hover': {
    color: Colors.purple,
  },
})

export const close = css({
  color: `${ Colors.blue } !important`,
  '&:hover': {
    color: `${ Colors.gold } !important`,
  },
})

export const modalClose = css(
  {
    zIndex: `210`,
    top: `1rem`,
    right: `1rem`,
    position: `absolute`,
    fontStyle: `normal`,
    textTransform: `uppercase`,
  },
  close,
  link
)
