import LatoBlackTTF from './Lato-Black.ttf'
import LatoBlackWOFF from './Lato-Black.woff'
import LatoBlackWOFF2 from './Lato-Black.woff2'
import OpenSansRegularTTF from './OpenSans-Regular.ttf'
import OpenSansRegularWOFF from './OpenSans-Regular.woff'
import OpenSansRegularWOFF2 from './OpenSans-Regular.woff2'

import KairosSansTTF from './kairos-sans.ttf'
import KairosSansWOFF from './kairos-sans.woff'
import KairosSansWOFF2 from './kairos-sans.woff2'

import OcraTTF from './OCRA.ttf'
import OcraWOFF from './OCRA.woff'
import OcraWOFF2 from './OCRA.woff2'
import OcrbTTF from './OCRB.ttf'
import OcrbWOFF from './OCRB.woff'
import OcrbWOFF2 from './OCRB.woff2'

export default {
  LatoBlackTTF,
  LatoBlackWOFF,
  LatoBlackWOFF2,
  OpenSansRegularTTF,
  OpenSansRegularWOFF,
  OpenSansRegularWOFF2,
  KairosSansTTF,
  KairosSansWOFF,
  KairosSansWOFF2,
  OcraTTF,
  OcraWOFF,
  OcraWOFF2,
  OcrbTTF,
  OcrbWOFF,
  OcrbWOFF2
}
