import React from 'react'

const GoodShepherd = props => (
  <svg
    x={0}
    y={0}
    viewBox="0 0 155 40"
    xmlSpace="preserve"
    {...props}
  >
    <path
      className="fill-current"
      d="M133.3 40h-.9v-4.7h-1.6v-.8h4.1v.8h-1.6V40zm-8.2 0H124l-2.7-4.4v.2c0 .5.1.9.1 1.3V40h-.8v-5.5h1.1l2.7 4.3v-.6-3.7h.8V40zm-10.6 0h-3.1v-5.5h3.1v.8h-2.2v1.5h2.1v.8h-2.1v1.7h2.2v.7zm-12.7 0l-1.6-4.6c0 .7.1 1.3.1 1.9V40h-.8v-5.5h1.3l1.5 4.3 1.6-4.3h1.3V40h-.9v-2.7-1.1-.8l-1.6 4.6h-.9zm-8.7 0H92l-2.7-4.4v.2c0 .5.1.9.1 1.3V40h-.8v-5.5h1.1l2.7 4.3v-.6-3.7h.8V40zm-11.8-5.5h.9V40h-.9v-5.5zM74 37.7l-.5-1.5c0-.1-.1-.3-.2-.5s-.1-.4-.1-.5c-.1.3-.2.6-.3 1l-.5 1.4H74zm.8 2.3l-.5-1.5h-2.1l-.6 1.5h-.9l2-5.5h1l2 5.5h-.9zm-10.2 0h-.9v-4.7h-1.6v-.8h4.1v.8h-1.6V40zm-10.8-2.2V40h-.9v-5.5h1.5c.7 0 1.2.1 1.6.4.3.3.5.7.5 1.2 0 .7-.4 1.2-1.1 1.5L57 40h-1l-1.3-2.2h-.9zm0-.7h.6c.4 0 .7-.1.9-.2.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.1-.5-.2-.9-.2h-.6v1.8zm-7 2.9h-3.1v-5.5h3.1v.8h-2.2v1.5h2.1v.8h-2.1v1.7h2.2v.7zm-10.4 0h-.9v-4.7h-1.6v-.8H38v.8h-1.6V40zm-8.2 0h-1.1l-2.7-4.4v.2c0 .5.1.9.1 1.3V40h-.8v-5.5h1.1l2.7 4.3v-.6-3.7h.8V40zm-10.6 0h-3.1v-5.5h3.1v.8h-2.2v1.5h2.1v.8h-2.1v1.7h2.2v.7zM15.2 0h8.7l-8 6.8L18 2.7c-7.2.1-11 6.5-12.6 11.6-.8 2.4-.8 4.1 0 5.4.6 1.1 1.7 1.6 3.3 1.6l2.9-5.3L19 9.9c.1 0-7.2 13.4-7.9 14.8l-7.7 6.6L7.2 24C1 24-1.2 20.6.7 14.6 2.4 9.1 8.9 0 15.2 0M46.1 13.2h-2.5l-3.1 5.7c-.5 1.3.2 1.8 1.5 1.6l4.1-7.3zm-3.9 10h-5.6c-1-1-.4-3.3 0-4.5.8-2.2 1.9-4.1 3.2-5.5h-2.3c.3.7-.2 2.8-.5 3.5-1.3 3.1-3.9 6.8-7.6 6.8-3.8.1-4-3.1-2.9-6 .5-1.5 1.4-3.1 2.4-4.3h-2c.3.7-.2 2.8-.5 3.5-1.3 3.1-3.9 6.8-7.6 6.8-4.4.1-3.8-4.2-2.5-7.1.9-1.9 2-3.4 3.3-4.6C21 10.6 22.4 10 24 10h4.9l-1.2 2.2 2.6-2.2h9.2l-1.2 2.2 2.6-2.2h6.8l1.6-3 7-5.9-9.3 18-4.8 4.1zm-17.5-10h-2.5l-3.1 5.7c-.5 1.3.2 1.8 1.5 1.6l4.1-7.3zm10.3 0h-2.5l-3.1 5.7c-.5 1.3.2 1.8 1.5 1.6l4.1-7.3zM51.6 15.9c-.6 5.3 2.1 8.7 7.6 8 2.2-.3 4.1-.7 5.9-2.1 2-1.5 3.3-3.5 3.9-5.9 1-3.7 0-6.1-.2-9.8-.1-1.6.1-3 1.2-3.6h5.4l-2.3 4.3L81.5 0H69.8c-3 0-4.9 3.8-5.3 6.4-.5 3.3.7 6.3.3 9.3-.3 2.4-2.3 5.7-5.6 5.7-2.5 0-3.5-1.4-3.8-3.6-.2-2.5 3.3-7.8 3.3-8.1-.7.7-7.1 5.7-7.1 6.2"
    />
    <path
      className="fill-current"
      d="M144.6 13.2h-2.5l-3.1 5.7c-.5 1.3.2 1.8 1.5 1.6l4.1-7.3zM84.9 30.8l4.2-7.6H74.8c-.8 0-1-.5-.6-1.6l2.9-5.6-6.2 6c-.8.8-1.4 1.2-2 1.2h-2.2l8.6-16 7-6L74 16.8l9.1-6.9-4.4 10.3h2c0-.7.2-1.6.6-2.6.9-2.4 2.1-4.3 3.8-5.7 1.4-1.2 2.9-1.8 4.3-1.8H91c1.4 0 2.6.7 2.7 2.1l-6.1 5.1 2.6-4.1h-2.3L85 18.6c-.4 1-.1 1.6.8 1.6h5l5.7-10.9 6.7-5.6-3.5 6.5c1.7 0 2.8.1 3.3.4 1.2.6 1.3 2.1.4 4.4-.7 1.7-2.6 4.5-4.3 5.2h2.7l6.9-13 7-6-8.3 15.6 8.7-7-4 10.3h1.8c0-.7.2-1.6.6-2.6.9-2.4 2.2-4.3 3.8-5.7 1.4-1.2 2.9-1.8 4.3-1.8h1.6c1.4 0 2.6.6 2.7 2.1l-6.1 5.1 2.6-4.1h-2.3l-2.9 5.4c-.4 1-.1 1.6.8 1.6h5.4l3.9-6.9c-.6-.3-.6-1-.6-1.6l7.8-8.1-3.3 6.6h5.7l-5.4 10h2c.5-2.2 1.9-4.9 3.3-6.6 1.5-1.8 3.6-3.4 6-3.4h1.9l2.1-3 6.7-5.8-8.7 17-5.7 4.9h-10.6c-1.1 0-1.3-.8-.8-2.4l4-7.6h-2.2c-1.5 2.4-5.2 9.1-5.4 9.2-.7.5-1.3.7-1.9.7h-15c-.8 0-1-.5-.6-1.6l2.9-5.6-6.2 6c-.8.8-1.4 1.2-2 1.2h-7c-.9 0-1-.8-.5-2.4 1.2-.7 1.9-1 2.8-2.1.9-1.1 2.1-3.3 1.5-4.7-.2-.5-.6-.8-1.2-.8l-6.1 11.4-6.9 6.3z"
    />
  </svg>
)

export default GoodShepherd
