/* eslint-disable max-len */
import { css } from 'emotion'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { Component } from 'react'
import { keyframes } from 'react-emotion'
import ReactPlayer from 'react-player'
import Responsive from 'react-responsive'
import GoodShepherd from '../assets/good-shepherd'
import SuperScarySnakes from '../assets/super-scary-snakes'
import Layout from '../components/layout'
import twitchCodes from '../components/twitchCodes'
import weapons from '../components/weapons'
import Colors from '../globals/colors'
import MQ from '../globals/mq'
import LogoBlue from '../images/black-future-88-blue.png'
import LogoGreen from '../images/black-future-88-green.png'
import LogoRed from '../images/black-future-88-red.png'
import Logo from '../images/black-future-88-rgba.png'
import Modal from '../components/Modal'
import Features from '../components/Features'
import Codes from '../components/Codes'
import { link, close } from '../globals/common'

// == variables
const animationTime = 180
const scanlinesWidth = `2px`
const opacityTime = `3s`

// == qualifiers
const supportsMixBlendMode =
  typeof window !== 'undefined' &&
  window.getComputedStyle(document.body).mixBlendMode
const supportBackgroundBlendMode =
  typeof window !== 'undefined' &&
  window.getComputedStyle(document.body).backgroundBlendMode

// == animations
const opacityFrames = keyframes({
  '0%': { opacity: `0.6` },
  '20%': { opacity: `0.3` },
  '35%': { opacity: `0.5` },
  '50%': { opacity: `0.8` },
  '60%': { opacity: `0.4` },
  '80%': { opacity: `0.7` },
  '100%': { opacity: `0.6` }
})

// == styles
const pinned = css({
  position: `fixed`,
  top: 0,
  left: 0,
  width: `100vw`,
  height: `100vh`,
  overflow: `hidden`,
  pointerEvents: `none`,
  '&:before': {
    content: `''`,
    position: `absolute`,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    pointerEvents: `none`
  }
})

const background = css({
  zIndex: `200`,
  opacity: `0.9`,
  backgroundColor: Colors.purple,
  backgroundSize: `100%`,
  pointerEvents: `all`,
  position: `fixed`,
  top: 0,
  left: 0,
  width: `100vw`,
  height: `100vh`,
  overflow: `hidden`
})

// == vhs effects
const scanlines = css(
  {
    zIndex: `300`,
    opacity: `0.6`,
    animation: `${ opacityFrames } ${ opacityTime } linear infinite`,
    '&:before': {
      backgroundImage: `linear-gradient(
      to bottom,
      transparent 50%,
      rgba(0,0,0,0.5) 51%
    )`,
      backgroundSize: `100% ${ scanlinesWidth }`
    }
  },
  pinned
)

// == content
const feature = css({
  position: `absolute`,
  display: `block`,
  width: `100%`,
  textAlign: `center`,
  opacity: 0,
  letterSpacing: `0.15rem`,
  fontVariationSettings: `'wght' 700, 'ital' 1, 'wdth' 70`,
  fontSynthesis: `none`,
  textShadow: `
    0 0 5px ${ Colors.white },
    0 0 20px ${ Colors.magenta },
    0 0 40px ${ Colors.magenta }, 0 0 60px ${ Colors.magenta }`,
  '&.active': {
    opacity: 1,
    transition: `opacity ${ animationTime }ms linear`
  },
  '&.past': {
    opacity: 0,
    transition: `opacity ${ animationTime }ms linear `
  }
})

// == content
const about = css({
  fontSize: `1.6rem`,
  fontVariationSettings: `'wght' 500, 'ital' 0, 'wdth' 70`,
  margin: `0 auto 2rem`,
  width: `60%`,
  em: {
    fontStyle: `normal`,
    display: `inline`,
    backgroundColor: `rgba(25, 0, 42, 0.72)`,
    padding: `0.4rem 0.6rem`,
    lineHeight: `1.9`
  }
})
const actions = css({
  position: `absolute`,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
  width: `100%`,
  paddingTop: `7rem`,
  em: {
    cursor: `pointer`,
    fontSize: `1.6rem`,
    fontVariationSettings: `'wght' 500, 'ital' 0, 'wdth' 70`,
    letterSpacing: `1px`,
    textTransform: `uppercase`,
    textAlign: `center`,
    color: Colors.black,
    fontStyle: `normal`,
    display: `inline`,
    backgroundColor: Colors.white,
    padding: `0.4rem 0.8rem`,
    margin: `0 0 1rem`,
    a: {
      color: Colors.black
    },
    // lineHeight: `1.9`,
    '&:hover': {
      color: Colors.white,
      backgroundColor: Colors.magenta,
      a: {
        color: Colors.white
      }
    }
  }
})
const content = css({
  position: `relative`,
  width: `100vw`,
  textAlign: `center`,
  fontSize: `2rem`,
  textTransform: `uppercase`,
  fontFamily: `Kairos`,
  color: Colors.white,
  marginTop: `-9rem`,
  span: [feature]
})

// == header
const navigation = css({
  position: `relative`,
  width: `100%`,
  display: `flex`,
  alignSelf: `flex-start`,
  alignItems: `center`,
  justifyContent: `space-between`,
  padding: `2rem 3rem 0`,
  color: Colors.white,
  fontWeight: `normal`,
  letterSpacing: `1px`,
  textTransform: `uppercase`
})
const navItem = css({
  display: `inline-block`,
  fontSize: `1rem`,
  padding: `0 1.8rem`,
  color: Colors.white,
  letterSpacing: `1px`,
  '&:first-child': {
    paddingLeft: 0
  }
})
const nav = css({
  display: `flex`,
  alignItems: `center`,
  marginBottom: `2rem`,
  a: [navItem, link],
  span: navItem
})
const when = css({
  fontSize: `1.4rem !important`,
  color: `${ Colors.gold } !important`
})

// == footer
const footer = css({
  display: `flex`,
  flexDirection: `column`,
  width: `58%`,
  margin: `0 auto`,
  paddingBottom: `3rem`
})
const team = css({
  position: `relative`,
  width: `100%`,
  display: `flex`,
  justifyContent: `center`,
  padding: `0 3rem 2rem`,
  color: Colors.white
})
const logo = css({
  cursor: `pointer`,
  '&:hover': {
    color: Colors.magenta
  }
})
const logos = css({
  display: `flex`,
  alignItems: `center`,
  a: [navItem, logo]
})

// == logos
const staticLogo = css({
  backgroundSize: `cover`,
  backgroundImage: `url(${ Logo })`,
  backgroundPosition: `center`
})
const rgbLogo = css({
  position: `relative`,
  overflow: `hidden`,
  backgroundImage: `url(${ LogoBlue })`,
  backgroundBlendMode: `lighten`,
  backgroundSize: `99%`,
  backgroundPosition: `center`,
  '&:before, &:after': {
    display: `none`,
    content: `''`,
    position: `absolute`,
    top: `0`,
    right: `0`,
    bottom: `0`,
    left: `0`,
    pointerEvents: `none`
  },
  '&:before': {
    display: `block`,
    backgroundImage: `url(${ LogoRed })`,
    backgroundBlendMode: `lighten`,
    backgroundSize: `99%`,
    backgroundPosition: `center`
  },
  '&:after': {
    display: `block`,
    backgroundImage: `url(${ LogoGreen })`,
    mixBlendMode: `screen`,
    backgroundSize: `99%`,
    backgroundPosition: `center`
  }
})
const sss = css({
  width: `16.625rem`,
  height: `1.9rem`
})
const gse = css({
  width: `10.75rem`,
  height: `2.875rem`
})
const codes = css({
  color: Colors.white,
  '&:hover': {
    color: Colors.magenta
  }
})

// const switchUrl = `https://youtube.com/watch?v=UgbHBPIxX80`
const switchUrl = `https://www.youtube.com/watch?v=pH7p2jJsA1w`
// const explainerUrl = `https://www.youtube.com/watch?v=iodYEqrvoxg`

// == responsive
const Landscape = props => <Responsive {...props} orientation={'landscape'} />
const Portrait = props => <Responsive {...props} orientation={'portrait'} />

class IndexPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      trailerUrl: null,
      trailerPlaying: false,
      featurePlaying: false,
      contentShowing: true,
      weaponsModalShowing: false,
      twitchCodesModalShowing: false,
      featureUrl: weapons[0].url,
      currentTwitchCode: null,
      currentTwitchFilter: 'all'
    }
    // trailer
    this.onTrailerClickHandler = this.onTrailerClickHandler.bind(this)
    this.onStopClickHandler = this.onStopClickHandler.bind(this)
    this.onTrailerEndedHandler = this.onTrailerEndedHandler.bind(this)
    // features modal
    this.onModalButtonClickHandler = this.onModalButtonClickHandler.bind(this)
    this.onModalCloseClickHandler = this.onModalCloseClickHandler.bind(this)
    // twitch codes modal
    this.onTwitchButtonClickHandler = this.onTwitchButtonClickHandler.bind(
      this
    )
    this.onTwitchCloseClickHandler = this.onTwitchCloseClickHandler.bind(this)
    this.onTwitchCodeFilterHandler = this.onTwitchCodeFilterHandler.bind(this)
    // codes
    this.onVideoClickHandler = this.onVideoClickHandler.bind(this)
    this.onTwitchCodeClickHandler = this.onTwitchCodeClickHandler.bind(this)
    this.onFeatureEndedHandler = this.onFeatureEndedHandler.bind(this)
    this.trailerRef = this.trailerRef.bind(this)
    this.featureRef = this.featureRef.bind(this)
    // buy beta
    this.onBuyBetaClickHandler = this.onBuyBetaClickHandler.bind(this)
  }
  componentDidMount () {
    this.animate()
  }
  componentWillUnmount () {
    clearInterval(this.timer)
  }
  animate () {
    let spans = this.refs.content.getElementsByTagName('span')
    let active = this.refs.content.getElementsByClassName('active')
    let i = 0
    let j = 1
    if (active.length === 0) {
      spans[0].classList.add('active')
    }
    this.timer = setInterval(() => {
      for (let i = 0; i < spans.length; i++) {
        spans[i].classList.remove('active')
      }
      for (let i = 0; i < spans.length; i++) {
        spans[i].classList.remove('past')
      }
      spans[j++ % spans.length].classList.add('active')
      spans[i++ % spans.length].classList.add('past')
    }, 3600)
  }
  // trailer
  onTrailerClickHandler (video) {
    this.setState({
      trailerUrl: video,
      trailerPlaying: true,
      featurePlaying: false,
      contentShowing: false,
      weaponsModalShowing: false
    })
  }
  onStopClickHandler () {
    this.trailerPlayer.seekTo(0)
    this.setState({
      trailerPlaying: false,
      contentShowing: true
    })
  }
  onTrailerEndedHandler () {
    this.setState({
      trailerPlaying: false,
      contentShowing: true
    })
  }
  // modal
  onModalButtonClickHandler () {
    this.setState({
      featurePlaying: true,
      weaponsModalShowing: true
    })
  }
  onModalCloseClickHandler () {
    this.featurePlayer.seekTo(0)
    this.setState({
      featurePlaying: false,
      weaponsModalShowing: false
    })
  }
  onTwitchButtonClickHandler () {
    this.setState({
      twitchCodesModalShowing: true
    })
  }
  onTwitchCloseClickHandler () {
    this.setState({
      twitchCodesModalShowing: false
    })
  }
  onTwitchCodeFilterHandler (filter) {
    this.setState({
      currentTwitchFilter: filter
    })
  }
  onVideoClickHandler (video, copy) {
    this.setState({
      featureUrl: video,
      featurePlaying: true
    })
  }
  onTwitchCodeClickHandler (code) {
    this.setState({
      currentTwitchCode: code
    })
  }
  onFeatureEndedHandler () {
    this.setState({
      featurePlaying: false
    })
  }
  onBuyBetaClickHandler () {
    window.XPayStationWidget.open()
  }
  trailerRef (player) {
    this.trailerPlayer = player
  }
  featureRef (player) {
    this.featurePlayer = player
  }
  render () {
    const {
      trailerPlaying,
      featurePlaying,
      contentShowing,
      weaponsModalShowing,
      twitchCodesModalShowing,
      trailerUrl,
      featureUrl,
      currentTwitchFilter,
      currentTwitchCode
    } = this.state
    return (
      <Layout>
        <Modal
          styles={{
            overflow: `hidden`,
            width: `85vw`,
            maxWidth: `90vw`,
            padding: `0.5rem`,
            margin: `4rem auto`,
            backgroundColor: Colors.purple,
            flexWrap: `no-wrap`,
            flexDirection: `row`,
            '@media only screen and (orientation: portrait)': {
              flexWrap: `wrap`
            }
          }}
          showing={weaponsModalShowing}
          layoutType="flex"
        >
          <Features
            array={weapons}
            url={featureUrl}
            playerRef={this.featureRef}
            playing={featurePlaying}
            clickHandler={this.onVideoClickHandler}
            closeHandler={this.onModalCloseClickHandler}
          />
        </Modal>
        <Modal
          styles={{
            width: `100vw`,
            height: `100vh`,
            backgroundColor: Colors.purple,
            flexDirection: `column`
          }}
          showing={twitchCodesModalShowing}
          layoutType="flex"
        >
          <Codes
            array={twitchCodes}
            current={currentTwitchCode}
            filter={currentTwitchFilter}
            filterHandler={this.onTwitchCodeFilterHandler}
            clickHandler={this.onTwitchCodeClickHandler}
            closeHandler={this.onTwitchCloseClickHandler}
          />
        </Modal>
        <div
          className={background}
          style={{
            display:
              weaponsModalShowing || twitchCodesModalShowing ? `block` : `none`
          }}
        />
        <div
          style={{
            width: `100%`,
            height: `100%`,
            position: `absolute`
          }}
        >
          <div className={scanlines} />
          <Portrait>
            <Img
              backgroundColor={`${ Colors.purple }`}
              style={{
                width: `100%`,
                height: `100%`,
                position: `absolute`
              }}
              fluid={this.props.data.keyartPortraitImage.childImageSharp.fluid}
            />
          </Portrait>
          <Landscape>
            <Img
              backgroundColor={`${ Colors.purple }`}
              style={{
                width: `100%`,
                height: `100%`,
                position: `absolute`
              }}
              fluid={this.props.data.keyartLandscapeImage.childImageSharp.fluid}
            />
          </Landscape>
        </div>
        <div
          style={{
            position: `absolute`,
            width: `100%`,
            height: `100%`,
            backgroundColor: `${ Colors.purple }`,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignCenter: `center`,
            opacity: trailerPlaying ? `1` : `0`
          }}
        >
          <ReactPlayer
            ref={this.trailerRef}
            className={css({
              position: `absolute`,
              top: 0,
              left: 0
            })}
            url={trailerUrl}
            width="100%"
            height="100%"
            volume={0.4}
            playing={trailerPlaying}
            onEnded={this.onTrailerEndedHandler}
            config={{
              youtube: {
                playerVars: {
                  controls: 0,
                  modestbranding: 1,
                  fs: 1
                }
              }
            }}
          />
        </div>
        <nav
          className={css`
            ${ navigation }
            & {
              @media only screen and (orientation: portrait) {
                flex-direction: column;
                justify-content: center;
                text-align: center;
              }
              ${ MQ.ipSE(css`
                flex-wrap: wrap;
                flex-direction: row;
              `) }
              ${ MQ.ip68(css`
                flex-wrap: wrap;
              `) }
              ${ MQ.ip8U(css`
                flex-wrap: wrap;
              `) }
              ${ MQ.ipXX(css`
                flex-wrap: wrap;
              `) }
            }
          `}
        >
          <div className={nav}>
            <Landscape>
              <div
                className={css(
                  {
                    width: `22.1145rem`,
                    height: `3.54rem`,
                    marginRight: `1.4rem`
                  },
                  supportsMixBlendMode !== 'undefined' &&
                    supportBackgroundBlendMode !== 'undefined'
                    ? rgbLogo
                    : staticLogo
                )}
              />
            </Landscape>
            <span className={when}>NOW AVAILABLE FOR PC + SWITCH</span>
          </div>
          <div className={nav}>
            <span
              onClick={this.onStopClickHandler}
              style={{ display: trailerPlaying ? `block` : `none` }}
              className={`${ navItem } ${ link } ${ close }`}
            >
              Stop Video
            </span>
            <span
              onClick={() => this.onTrailerClickHandler(switchUrl)}
              style={{ display: trailerPlaying ? `none` : `block` }}
              className={`${ navItem } ${ link }`}
            >
              Watch Trailer
            </span>
            <a href="https://store.steampowered.com/app/751820/Black_Future_88/">
              BUY ON STEAM
            </a>
            <a href="https://www.nintendo.com/games/detail/black-future-88-switch/">
              BUY ON SWITCH
            </a>
            <a href="https://discordapp.com/invite/goodshepherdgames">
              Join the Community
            </a>
          </div>
        </nav>
        <div
          style={{ display: contentShowing ? `block` : `none` }}
          className={content}
          ref="content"
        >
          <p className={about}>
            <em>
              Black Future ’88 is a Synth-Punk roguelike 2D action Shooter
            </em>
            <br />
            <em>set in a stylish alternative version of 1988</em>
          </p>
          <span>climb an evolving tower</span>
          <span>kill the wardens</span>
          <span>ruin friendships with couch co-op</span>
          <span>unlock over 50 weapons and buffs</span>
          <span>before your heart explodes</span>
          <div className={actions}>
            <em>
              <a href="https://store.steampowered.com/app/751820/Black_Future_88/">
                BUY ON STEAM
              </a>
            </em>
            <em>
              <a href="https://www.nintendo.com/games/detail/black-future-88-switch/">
                BUY ON SWITCH
              </a>
            </em>
            <em onClick={() => this.onModalButtonClickHandler()}>
              Learn More About The Game
            </em>
          </div>
        </div>
        <div>
          <div
            className={css`
              ${ footer }
              & {
                @media only screen and (orientation: portrait) {
                  width: 100% !important;
                }
                ${ MQ.ipSE(css`
                  width: 64%;
                  padding: 0 2rem 1rem;
                `) }
                ${ MQ.ip68(css`
                  padding: 0 3rem 3rem;
                `) }
                ${ MQ.ip8U(css`
                  padding: 0 3rem 3rem;
                `) }
                ${ MQ.ipXX(css`
                  padding: 0 3rem 3rem;
                `) }
              }
              opacity: ${ trailerPlaying ? 0 : 1 };
            `}
          >
            <Portrait>
              <div
                className={css`
                  width: 100%;
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                `}
              >
                <div
                  className={css`
                    ${ MQ.sm(css`
                      width: 60vw;
                      height: 9vh;
                    `) }
                    ${ MQ.ipSE(css`
                      width: 90vw;
                      height: 8.5vh;
                    `) }
                    ${ MQ.ip68(css`
                      width: 90vw;
                      height: 8vh;
                    `) }
                    ${ MQ.ip8U(css`
                      width: 90vw;
                      height: 7vh;
                    `) }
                    ${ MQ.ipXX(css`
                      width: 90vw;
                      height: 7vh;
                    `) }
                    ${
      supportsMixBlendMode !== 'undefined' &&
                      supportBackgroundBlendMode !== 'undefined'
        ? rgbLogo
        : staticLogo
      } `}
                />
              </div>
            </Portrait>
          </div>
          <nav className={team}>
            <div className={logos}>
              <a target="_blank" href="https://twitter.com/superscarysnake">
                <SuperScarySnakes className={sss} />
              </a>
              <a target="_blank" href="https://twitter.com/goodshepherdent">
                <GoodShepherd className={gse} />
              </a>
              <a
                target="_blank"
                onClick={() => this.onTwitchButtonClickHandler()}
              >
                <div className={codes}>REVEAL TWITCH CODES</div>
              </a>
            </div>
          </nav>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query {
    keyartLandscapeImage: file(relativePath: { regex: "/landscape/" }) {
      childImageSharp {
        fluid(maxWidth: 5120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    keyartPortraitImage: file(relativePath: { regex: "/portrait/" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logoImage: file(relativePath: { regex: "/rgba/" }) {
      childImageSharp {
        fluid(maxWidth: 1930) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
