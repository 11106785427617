/* eslint-disable max-len */
const twitchCodes = [
  {
    id: 1,
    name: 'Jupiter Core: Fast Clocking',
    type: 'all boss',
    description: 'Greatly reduces cooldown of dashing.',
  },
  {
    id: 2,
    name: 'Juno Core',
    type: 'all boss',
    description: 'Create a hot beam when you dash.',
  },
  {
    id: 3,
    name: 'Saving Grace',
    type: 'all boss',
    description:
      'Longer dashes. Dashing through bullets can sometimes heal you.',
  },
  {
    id: 4,
    name: 'Prophet Core: Local Area Nullifier',
    type: 'all boss',
    description: 'Dashing destroys nearby projectiles.',
  },
  {
    id: 5,
    name: 'Saving Grace',
    type: 'all boss',
    description: 'Dashing through bullets can sometimes heal you.',
  },
  {
    id: 6,
    name: 'Tempest Core: Deep Shadows',
    type: 'all boss',
    description:
      'Dashing creates shadow copies of yourself. Shadows use your ammo. Cursed.',
  },
  {
    id: 7,
    name: 'Uber Core: Ground Slam',
    type: 'all boss',
    description: 'Downward dash and hit the ground with a powerful attack.',
  },
  {
    id: 8,
    name: 'Vagrant Core',
    type: 'all boss',
    description:
      'Gain tremendous dash mobility but running consumes your deadlock time.',
  },
  {
    id: 9,
    name: 'Animal Heart',
    type: 'all buff',
    description: 'Faster firing speed if you have a low blood count.',
  },
  {
    id: 10,
    name: 'Bandoliers',
    type: 'all buff',
    description: 'Find more ammo in weapons, higher ammo max.',
  },
  {
    id: 11,
    name: 'Black Mirror',
    type: 'all buff',
    description: 'Your projectiles can now bounce.',
  },
  {
    id: 12,
    name: "Black Rabbit's Foot",
    type: 'all buff',
    description: 'Lower drop rates, but better items.',
  },
  {
    id: 13,
    name: 'BLAK MASK',
    type: 'all buff',
    description:
      'Deadlock time moves twice as fast. Killing enemies restores 5 seconds.',
  },
  {
    id: 14,
    name: 'Blak Threader',
    type: 'all buff',
    description:
      'Your deadlock time ticks down faster, but dashing through bullets will earn you back time.',
  },
  {
    id: 15,
    name: 'Blood Bait',
    type: 'all buff',
    description:
      'SKYMELT spawns more enemies, but gives you more and better weapons. Less items get dropped.',
  },
  {
    id: 16,
    name: 'BLOOD MASK',
    type: 'all buff',
    description: 'Increased damage for you and enemies.',
  },
  {
    id: 17,
    name: 'Blood Rounds',
    type: 'all buff',
    description: 'In your darkest hour, turn your blood to ammo.',
  },
  {
    id: 18,
    name: 'Bullet Efficiency',
    type: 'all buff',
    description:
      "Sometimes firing weapons doesn't use ammo, but all energy weapons break when you try to pick them up.",
  },
  {
    id: 19,
    name: 'Deadlock Extension',
    type: 'all buff',
    description: 'Get a little extra time before your heart explodes.',
  },
  {
    id: 20,
    name: 'Dead of Night',
    type: 'all buff',
    description: 'Kills grant an extra few seconds of deadlock time.',
  },
  {
    id: 21,
    name: 'Death From Above',
    type: 'all buff',
    description:
      'Double combo bonuses for killing things while airborne, enemies drop extra money on combo streaks.',
  },
  {
    id: 22,
    name: 'DeNullifier',
    type: 'all buff',
    description: 'Pick up nullified projectiles and use them as ammo.',
  },
  {
    id: 23,
    name: 'Doomed Soul',
    type: 'all buff',
    description:
      'Some armor, all your blood is also armor. Your blood can no longer become infected.',
  },
  {
    id: 24,
    name: 'Doomed Valor',
    type: 'all buff',
    description:
      'At great cost to your max blood count, fire faster and do more damage.',
  },
  {
    id: 25,
    name: 'Dumb Bullets',
    type: 'all buff',
    description: 'Bullets move slower, but do more damage.',
  },
  {
    id: 26,
    name: 'Dangerous when Laserous',
    type: 'all buff',
    description: 'Energy Weapons have faster firing speed.',
  },
  {
    id: 27,
    name: 'Second Thoughts',
    type: 'all buff',
    description: 'Get an extra dash charge.',
  },
  {
    id: 28,
    name: 'Faith Healer',
    type: 'all buff',
    description: 'Regain more time from the Overstimulator perk.',
  },
  {
    id: 29,
    name: 'FMLRIZER',
    type: 'all buff',
    description: 'Faster attack speed.',
  },
  {
    id: 30,
    name: 'A Fancy Hat',
    type: 'all buff',
    description:
      'Extra charisma gets you shop discounts and more options in buff selection.',
  },
  {
    id: 31,
    name: 'Firewall',
    type: 'all buff',
    description: 'Become fire- and explosion-proof when combo level is high.',
  },
  {
    id: 32,
    name: 'Flak Jacket',
    type: 'all buff',
    description: 'Less damage from explosions.',
  },
  {
    id: 33,
    name: 'Flexible Translation',
    type: 'all buff',
    description: 'Dash up/down + left/right',
  },
  {
    id: 34,
    name: 'Gemini',
    type: 'all buff',
    description:
      'Two great tastes that taste great together deserve an extra dash charge.',
  },
  {
    id: 35,
    name: 'Grim Determination',
    type: 'all buff',
    description:
      'More damage from melee attacks but your clumsy hands slow down ranged weapons.',
  },
  {
    id: 36,
    name: 'Grim Knight',
    type: 'all buff',
    description: 'Your Black Richter is upgraded to the Grim Richter.',
  },
  {
    id: 37,
    name: 'Guntuition',
    type: 'all buff',
    description:
      'All weapons on the floor fire when you attack, but SKYMELT gets upgraded.',
  },
  {
    id: 38,
    name: 'Heavy Boots',
    type: 'all buff',
    description:
      'Destroy everything nearby when air dropping from high places.',
  },
  {
    id: 39,
    name: 'Heavy Belt',
    type: 'all buff',
    description: 'Carry an additional weapon, but weapons can break. Cursed.',
  },
  {
    id: 40,
    name: 'Insectivorous',
    type: 'all buff',
    description: 'Higher weapon drop rate, but the weapons are worse.',
  },
  {
    id: 41,
    name: 'Jr. Anarchist',
    type: 'all buff',
    description: 'Become immune to fire.',
  },
  {
    id: 42,
    name: 'Kill Repeater',
    type: 'all buff',
    description: 'Combo Buffs now activate earlier',
  },
  {
    id: 43,
    name: 'Kwang-Grade Stompers',
    type: 'all buff',
    description: 'Your last jump shoots grenades.',
  },
  {
    id: 44,
    name: 'Living Weapon',
    type: 'all buff',
    description: 'An extra dash for the embodiment of chaos.',
  },
  {
    id: 45,
    name: 'Raw Chrono',
    type: 'all buff',
    description:
      'Get an extra 3:00 to live, lose 2 random buffs. Skymelt gets upgraded.',
  },
  {
    id: 46,
    name: 'Lucky Idol',
    type: 'all buff',
    description: 'No item drops, just more weapons. Weapons are better.',
  },
  {
    id: 47,
    name: 'Meat Nickels',
    type: 'all buff',
    description: "Heals are better and give you armor if you don't have any.",
  },
  {
    id: 48,
    name: 'Crude Chrono',
    type: 'all buff',
    description: 'Get an extra 2:00 to live, lose 1 random buff.',
  },
  {
    id: 49,
    name: 'MELT Core',
    type: 'all buff',
    description:
      'Your dashes refresh instantly, but they cost deadlock time to use.',
  },
  {
    id: 50,
    name: 'M. Fission',
    type: 'all buff',
    description: 'Drop a bomb with a short fuse when you use your dash.',
  },
  {
    id: 51,
    name: 'Nails Implant',
    type: 'all buff',
    description:
      'Spit out many nails when you get hit. You take extra damage. Cursed.',
  },
  {
    id: 52,
    name: 'Heavy Needler Set',
    type: 'all buff',
    description: "You've modified your Needler into a stronger weapon.",
  },
  {
    id: 53,
    name: 'Needle Threader',
    type: 'all buff',
    description: 'Dashing through bullets will heal you for even more.',
  },
  {
    id: 54,
    name: 'Neon Chrome',
    type: 'all buff',
    description:
      'Energy weapons deal more damage, but all weapons use 2x ammo.',
  },
  {
    id: 55,
    name: 'Night Piercer',
    type: 'all buff',
    description: 'You can dash through walls and do damage to enemies. Cursed.',
  },
  {
    id: 56,
    name: 'Night Piercer Set',
    type: 'all buff',
    description: "You've modified your Night Nail into a stronger weapon.",
  },
  {
    id: 57,
    name: 'Night Terror',
    type: 'all buff',
    description:
      'When teleporting from a night weapon, nullify all nearby projectiles.',
  },
  {
    id: 58,
    name: 'Nuke Suit',
    type: 'all buff',
    description: 'Explosions can only do 1 damage to you.',
  },
  {
    id: 59,
    name: 'Optimistic Locking',
    type: 'all buff',
    description:
      'You cause more damage when at high blood count, but Skymelt spawns more enemies.',
  },
  {
    id: 60,
    name: 'Overstimulator',
    type: 'all buff',
    description:
      'When your blood count is 5 or less, you regain some time with melee kills.',
  },
  {
    id: 61,
    name: 'Pounding Heart',
    type: 'all buff',
    description:
      'Stronger time slow effect, dashing and firing ignores slow motion.',
  },
  {
    id: 62,
    name: 'Precious Moments',
    type: 'all buff',
    description:
      'Get bonuses when picking up recently dropped items. Catching weapons in the air powers them up briefly.',
  },
  {
    id: 63,
    name: "Rabbit's Foot",
    type: 'all buff',
    description: 'Weapons and items drop more often and are better.',
  },
  {
    id: 64,
    name: 'Remorse Suppressor',
    type: 'all buff',
    description: 'Killing things lets you immediately use the dash.',
  },
  {
    id: 65,
    name: 'Returner',
    type: 'all buff',
    description: 'Reflected projectiles seek back to their sender.',
  },
  {
    id: 66,
    name: 'Sandbagger',
    type: 'all buff',
    description: 'Your starter pistol is vastly upgraded.',
  },
  {
    id: 67,
    name: 'Sequence Optimizer',
    type: 'all buff',
    description: "Weapons don't cost ammo when your combo level is high.",
  },
  {
    id: 68,
    name: 'Sharper Boots',
    type: 'all buff',
    description: 'Fast dropping can now slice projectiles that are underfoot.',
  },
  {
    id: 69,
    name: 'Banshee Knight',
    type: 'all buff',
    description:
      'Sioux becomes invincible while Precious Moments is activated.',
  },
  {
    id: 70,
    name: 'Skyvader',
    type: 'all buff',
    description: "You've combined your weapons into the skyvader.",
  },
  {
    id: 71,
    name: 'Spiteful Heart',
    type: 'all buff',
    description: 'Killing synth-heads refunds a little ammo.',
  },
  {
    id: 72,
    name: 'Stolen Goods',
    type: 'all buff',
    description: 'Your friend gets revived when you kill the next warden.',
  },
  {
    id: 73,
    name: 'Synaptic Trigger',
    type: 'all buff',
    description: 'Firing causes your off-hand weapon(s) to fire. Cursed.',
  },
  {
    id: 74,
    name: 'Heart Time',
    type: 'all buff',
    description:
      'Trade 3:00 of deadlock time to regain all your blood and ammo. Cursed.',
  },
  {
    id: 75,
    name: 'Time Reaper',
    type: 'all buff',
    description:
      'Trade most of your deadlock time, killing enemies replenishes 0:03 of time. Cursed.',
  },
  {
    id: 76,
    name: 'Mimicry',
    type: 'all buff',
    description: 'All chests are replaced with mimics.',
  },
  {
    id: 77,
    name: 'True Believer',
    type: 'all buff',
    description: "Slice nearby enemy projectiles whenever you're teleported.",
  },
  {
    id: 78,
    name: 'Vantasm',
    type: 'all buff',
    description: 'All weapons fire faster, but your blood is now infected.',
  },
  {
    id: 79,
    name: 'Wormfood',
    type: 'all buff',
    description: 'The white substance falls apart in your hands.',
  },
  {
    id: 80,
    name: 'Heavyboot',
    type: 'all buff',
    description: 'Destroy all the target drones using just Heavy Boots.',
  },
  {
    id: 81,
    name: 'Bigger Explosions',
    type: 'all buff',
    description: 'Explosions are much bigger.',
  },
  {
    id: 82,
    name: 'CINDER',
    type: 'all enemy',
    description:
      'A extremely heavy air unit that is only able to move due to his built-in warp-reactor. Cinder always drops a hot weapon.',
  },
  {
    id: 83,
    name: 'Nuclear Option',
    type: 'all skymelt',
    description: 'All explosions are bigger.',
  },
  {
    id: 84,
    name: 'Bolster',
    type: 'all skymelt',
    description: 'Air Snipers and Microwave Bombs are always cursed.',
  },
  {
    id: 85,
    name: 'corpse exploder',
    type: 'all skymelt',
    description: 'Enemies can now explode when they die.',
  },
  {
    id: 86,
    name: 'Hunters come in twos',
    type: 'all skymelt',
    description: 'Hunters will now come in pairs.',
  },
  {
    id: 87,
    name: 'Heavy Money',
    type: 'all skymelt',
    description: "Money is heavier and won't attract to you as well.",
  },
  {
    id: 88,
    name: 'Hunted',
    type: 'all skymelt',
    description: "You're being hunted.",
  },
  {
    id: 89,
    name: 'Less Pickup Time',
    type: 'all skymelt',
    description:
      'Skymelt will absorb drop items left on the ground (not weapons) even faster.',
  },
  {
    id: 90,
    name: 'Less deadlock time',
    type: 'all skymelt',
    description: 'You have less time before your heart explodes.',
  },
  {
    id: 91,
    name: 'More Cursed Enemies',
    type: 'all skymelt',
    description: 'Skymelt is more likely to create cursed enemies.',
  },
  {
    id: 92,
    name: 'More Enemies',
    type: 'all skymelt',
    description: 'Enemy waves are bigger.',
  },
  {
    id: 93,
    name: 'Toxic Gas',
    type: 'all skymelt',
    description:
      'Explosive gas is now toxic and causes you to lose deadlock time while standing in it.',
  },
  {
    id: 94,
    name: 'Upgraded traps',
    type: 'all skymelt',
    description: 'All traps are upgraded.',
  },
  {
    id: 95,
    name: 'Trigger Fingers',
    type: 'all skymelt',
    description: 'Better firing speed.',
  },
  {
    id: 96,
    name: 'Baleen Cannon',
    type: 'all weapon',
    description: 'A rejiggered shotgun that shoots many piercing shards.',
  },
  {
    id: 97,
    name: 'The Beekeeper',
    type: 'all weapon',
    description: 'A high rate of fire missile delivery system.',
  },
  {
    id: 98,
    name: 'Black Richter',
    type: 'all weapon',
    description: 'Powerful melee weapon, scales with your curse level. Cursed.',
  },
  {
    id: 99,
    name: 'Black Ulver',
    type: 'all weapon',
    description: 'A slow but very powerful repeater.',
  },
  {
    id: 100,
    name: 'Blak CRAT.r',
    type: 'all weapon',
    description:
      'A slow, but reliable energy hammer that also gives you the ability do slam jumps which also nullify nearby projectiles. Cursed.',
  },
  {
    id: 101,
    name: 'Blak Nail',
    type: 'all weapon',
    description:
      'A heavy and slow Night Nail that slows down time when you hit enemies.',
  },
  {
    id: 102,
    name: 'Blak Rifle',
    type: 'all weapon',
    description:
      'A high rate of fire rifle that shoots seeking bullets. Uses your deadlock time as ammo. Cursed.',
  },
  {
    id: 103,
    name: 'Blak Wave',
    type: 'all weapon',
    description:
      'Extremely powerful wave cannon that uses your deadlock time as its ammo source. This will kill you. Cursed.',
  },
  {
    id: 104,
    name: 'Blink Cannon',
    type: 'all weapon',
    description:
      'An extremely powerful cannon weapon that uses your dash charges as its ammo source.',
  },
  {
    id: 105,
    name: 'Blood Lance',
    type: 'all weapon',
    description:
      "A weak melee lance that's powered up by combos. Will refund health at the end of high combos.",
  },
  {
    id: 106,
    name: 'Bouncer Shotgun',
    type: 'all weapon',
    description: 'A shotgun with bouncing bullets.',
  },
  {
    id: 107,
    name: 'Ceramic Sawed-off Shotgun',
    type: 'all weapon',
    description: 'Ceramic weapons break when they run out of ammo.',
  },
  {
    id: 108,
    name: 'Cluster Launcher',
    type: 'all weapon',
    description:
      'An explosive launcher that shoots several small grenades that explode when they touch the ground.',
  },
  {
    id: 109,
    name: 'CRAT.r',
    type: 'all weapon',
    description:
      'A slow, but reliable energy hammer that also gives you the ability do slam jumps.',
  },
  {
    id: 110,
    name: 'Das Hammer',
    type: 'all weapon',
    description: 'A hammer.',
  },
  {
    id: 111,
    name: 'Death Knell',
    type: 'all weapon',
    description: 'An upgraded Ghost Nail.',
  },
  {
    id: 112,
    name: 'Electron Pistol',
    type: 'all weapon',
    description: 'A mostly accurate energy weapon.',
  },
  {
    id: 113,
    name: 'Electron Shotgun',
    type: 'all weapon',
    description: 'An electron spreader.',
  },
  {
    id: 114,
    name: 'Endless Night',
    type: 'all weapon',
    description: "A Night Nail that doesn't use ammunition. Cursed.",
  },
  {
    id: 115,
    name: 'FMLR LZR',
    type: 'all weapon',
    description: 'Heavy kick, weak projectiles, energy based.',
  },
  {
    id: 116,
    name: 'Gas Rifle',
    type: 'all weapon',
    description:
      'An electron rifle that leaves a cloud of explosive gas at the impact point.',
  },
  {
    id: 117,
    name: 'Gas Sighted Nail Smelter',
    type: 'all weapon',
    description:
      'A standard issue Nail Smelter, but with the addition of gas sighting.',
  },
  {
    id: 118,
    name: 'Ghost Nail',
    type: 'all weapon',
    description:
      'A crude but effective nail weapon that uses your deadlock time for ammo.',
  },
  {
    id: 119,
    name: 'Glamr Hammer',
    type: 'all weapon',
    description:
      'A heavy pistol, when out of ammo its melee attacks can charm some enemies.',
  },
  {
    id: 120,
    name: 'Gonnerator',
    type: 'all weapon',
    description:
      'An extremely powerful revolver that slows down your falling speed when using in the air.',
  },
  {
    id: 121,
    name: 'Greenback Repeater',
    type: 'all weapon',
    description: 'A high rate of fire rifle that shoots your own money.',
  },
  {
    id: 122,
    name: 'The Green Feed',
    type: 'all weapon',
    description: 'A money powered shotgun. Enemies drop more money.',
  },
  {
    id: 123,
    name: 'Dredgling Grenade Launcher',
    type: 'all weapon',
    description: 'A slow grenade launcher.',
  },
  {
    id: 124,
    name: 'Grim Richter',
    type: 'all weapon',
    description: 'A heavy melee weapon.',
  },
  {
    id: 125,
    name: 'Hauto Protector',
    type: 'all weapon',
    description:
      "An ammo hungry shotgun that's devestating at close range. Can block enemy projectiles. Hot.",
  },
  {
    id: 126,
    name: 'Heavy Blink Cannon',
    type: 'all weapon',
    description:
      'A slower but more powerful version of the blink cannon that also provides extra dash charges.',
  },
  {
    id: 127,
    name: 'Heavy Electron Pistol',
    type: 'all weapon',
    description: 'A very heavy upgrade.',
  },
  {
    id: 128,
    name: 'Heavy Needler',
    type: 'all weapon',
    description:
      'A heavy but low ammo nail machine that gets thrown when it runs out of ammo. Hitting an enemy with a thrown weapon will reload it.',
  },
  {
    id: 129,
    name: 'Heavy Night Nail',
    type: 'all weapon',
    description: 'The heaviest Night Nail.',
  },
  {
    id: 130,
    name: 'Kinetic Hot Ripper',
    type: 'all weapon',
    description:
      "A powerful lance that's briefly powered up and heated when dashing.",
  },
  {
    id: 131,
    name: 'Hot Shot',
    type: 'all weapon',
    description: 'A shotgun that shoots hot projectiles.',
  },
  {
    id: 132,
    name: 'Kinetic Lance',
    type: 'all weapon',
    description:
      'A melee lance that uses ammo. Very weak. Dashing will briefly power it up to do more damage.',
  },
  {
    id: 133,
    name: "Lil' Brother",
    type: 'all weapon',
    description:
      "The Lil' Brother Micro-Doser is a high velocity grenade repeater.",
  },
  {
    id: 134,
    name: 'Long Barrel Shotgun',
    type: 'all weapon',
    description:
      'A shotgun with tight bullet coupling and relatively long reach.',
  },
  {
    id: 135,
    name: 'The Moneyshot',
    type: 'all weapon',
    description:
      'High kick, uses your money as its ammo source, sometimes does power shots. Cursed.',
  },
  {
    id: 136,
    name: 'weapon.moneyshot.tip1',
    type: 'all weapon',
    description:
      'Weapons like the Moneyshot shoot your money, but they can also make you rich by turning ammo into money.',
  },
  {
    id: 137,
    name: 'Nail Machine',
    type: 'all weapon',
    description: 'A high rate of fire nail machine that shoots piercing nails.',
  },
  {
    id: 138,
    name: 'Nail Smelter',
    type: 'all weapon',
    description:
      'A high force railgun which shoots hot nails that can hit multiple targets.',
  },
  {
    id: 139,
    name: 'Needler',
    type: 'all weapon',
    description:
      'A lightweight nail machine that gets thrown when it runs out of ammo. Hitting an enemy with a thrown weapon will reload it.',
  },
  {
    id: 140,
    name: 'Night Machine',
    type: 'all weapon',
    description:
      'A high rate of fire nail machine, killing hits can sometimes cause you to swap places with the target. Cursed.',
  },
  {
    id: 141,
    name: 'Night Nail',
    type: 'all weapon',
    description:
      'A piercing nail gun that causes you to swap position with the enemies you hit.',
  },
  {
    id: 142,
    name: 'Night Piercer',
    type: 'all weapon',
    description: 'A stronger night nail that can also slice up enemy bullets.',
  },
  {
    id: 143,
    name: 'Night Stiletto',
    type: 'all weapon',
    description:
      'A heavy but powerful cursed blade. Always returns bullets, swaps positions with hit enemies.',
  },
  {
    id: 144,
    name: 'Null Mass Cannon',
    type: 'all weapon',
    description: 'A profoundly powerful but cursed Blink Cannon.',
  },
  {
    id: 145,
    name: 'The Paych3k',
    type: 'all weapon',
    description:
      'A money-powered rifle that only does powershots. Money Hungry. Cursed.',
  },
  {
    id: 146,
    name: "Lil' Pitchfork",
    type: 'all weapon',
    description: 'The bigger and hotter version of the Long Barrel Shotgun',
  },
  {
    id: 147,
    name: 'Rocket Launcher',
    type: 'all weapon',
    description: 'Slow and cumbersome explosive launcher.',
  },
  {
    id: 148,
    name: 'Rusty Repeater',
    type: 'all weapon',
    description:
      "A small repeater that can shoot two bullets at a time if you're far enough away.",
  },
  {
    id: 149,
    name: 'Sandbagger Pistol',
    type: 'all weapon',
    description:
      'Just a regular old starter pistol, but with a little more umph.',
  },
  {
    id: 150,
    name: 'Skyvader',
    type: 'all weapon',
    description:
      'A heavy electron upgrade, that can also block and reflect bullets',
  },
  {
    id: 151,
    name: 'Snake Bite',
    type: 'all weapon',
    description: 'A short range shotgun that always shoots two bullets.',
  },
  {
    id: 152,
    name: "Survivor's Pistol",
    type: 'all weapon',
    description: 'A small and simple pistol.',
  },
  {
    id: 153,
    name: 'Stasis Launcher',
    type: 'all weapon',
    description:
      "Launch a slow moving projectile that will stun anything in it's impact area.",
  },
  {
    id: 154,
    name: 'Stasis Pistol',
    type: 'all weapon',
    description: 'An electron pistol that can stun some enemies.',
  },
  {
    id: 155,
    name: 'STS-89',
    type: 'all weapon',
    description:
      'A crude bat that does poor damage, but reflects projectiles very well.',
  },
  {
    id: 156,
    name: 'Super Heavy Laser',
    type: 'all weapon',
    description: 'A very inaccurate electron repeater.',
  },
  {
    id: 157,
    name: 'Sure Shot',
    type: 'all weapon',
    description: 'A slow repeater that shoots tracking bullets.',
  },
  {
    id: 158,
    name: 'Vapour Pistol',
    type: 'all weapon',
    description:
      'A heavy pistol that shoots bullets that leave trails of explosive gas.',
  },
  {
    id: 159,
    name: 'Vapour Smelter',
    type: 'all weapon',
    description:
      'A high accuracy and ammo hungry wave cannon that fires a hot beam. Generates explosive gas from the weapon.',
  },
  {
    id: 160,
    name: 'Viper Repeater',
    type: 'all weapon',
    description:
      'The Vapour Smelter emits gas from the gun while you fire it and can self-ignite if used at short range.',
  },
  {
    id: 161,
    name: 'Wave Cannon',
    type: 'all weapon',
    description: 'A high accuracy energy weapon that devours ammo.',
  },
  {
    id: 162,
    name: 'Zip Rifle',
    type: 'all weapon',
    description: 'High rate of fire rifle that shoots bouncing bullets.',
  },
]

export default twitchCodes
