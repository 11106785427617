/* eslint-disable max-len */
const weapons = [
  {
    id: 0,
    label: `The Story`,
    description: `Take on the mantle of one of the last remaining survivors, and fight your way to the top of the tower to kill the architect and stop the endless nuclear rain.`,
    url: `https://player.vimeo.com/video/333436823`,
  },
  {
    id: 1,
    label: `The Night Piercer`,
    description: `A strong piercing nail gun that causes you to swap position with the enemies you hit. It can also slice up enemy bullets.`,
    url: `https://player.vimeo.com/video/333164470`,
  },
  {
    id: 2,
    label: `The Money Shot`,
    description: `A cursed ranged weapon with high kick. It uses your money as its ammo source and sometimes does power shots.`,
    url: `https://player.vimeo.com/video/333164314`,
  },
  {
    id: 3,
    label: `The Gonnerator`,
    description: `An extremely powerful revolver that slows down your falling speed when using in the air.`,
    url: `https://player.vimeo.com/video/333164238`,
  },
  {
    id: 4,
    label: `The Blood Lance`,
    description: `A weak melee lance that's powered up by combos. However, it will refund health at the end of high combos in battle.`,
    url: `https://player.vimeo.com/video/333164167`,
  },
  {
    id: 5,
    label: `The Blink Cannon`,
    description: `An extremely powerful cannon weapon that uses your dash charges as its ammo source.`,
    url: `https://player.vimeo.com/video/333164088`,
  },
  {
    id: 6,
    label: `The Baleen Cannon`,
    description: `A rejiggered shotgun that shoots many piercing shards.`,
    url: `https://player.vimeo.com/video/333163996`,
  },
  {
    id: 7,
    label: `The Kinetic Lance`,
    description: `A very weak melee lance that uses ammo. Dashing will briefly power it up to do more damage.`,
    url: `https://player.vimeo.com/video/333163831`,
  },
  {
    id: 8,
    label: `The Vapour Smelter`,
    description: `The Vapour Smelter emits gas while you fire it and can self-ignite if used at short range.`,
    url: `https://player.vimeo.com/video/333164602`,
  },
]

export default weapons
