import React, { useState } from 'react'
import { css } from 'emotion'
import Colors from '../globals/colors'
import { close } from '../globals/common'

const item = css({
  cursor: `pointer`,
  fontSize: `1rem`,
  fontVariationSettings: `'wght' 500, 'ital' 0, 'wdth' 70`,
  letterSpacing: `1px`,
  textTransform: `uppercase`,
  textAlign: `left`,
  color: Colors.black,
  fontStyle: `normal`,
  padding: `0.6rem 0.8rem`,
  lineHeight: `1.9`,
  marginBottom: `0`,
  backgroundColor: Colors.teal,
})
const links = css({
  '&:hover': {
    color: Colors.white,
    backgroundColor: Colors.magenta,
  },
})
const itemActive = css({
  color: Colors.white,
  backgroundColor: Colors.magenta,
})
const twitchCodeDescription = css({
  textAlign: `left`,
  marginTop: `0.5rem`,
  marginBottom: `1rem`,
  color: Colors.white,
  fontSize: `1rem`,
  fontVariationSettings: `'wght' 500, 'ital' 0, 'wdth' 70`,
  fontStyle: `normal`,
  lineHeight: `1.5`,
  transition: `all 0.2s ease-in-out`,
})
const featureLabel = css({ textTransform: `uppercase` })
const filters = css({
  textAlign: `center`,
  backgroundColor: Colors.white,
  '&:hover': {
    backgroundColor: Colors.orange,
  },
})
const activeFilter = css({
  backgroundColor: Colors.orange,
})
const labels = css({
  cursor: `default`,
  color: Colors.white,
  backgroundColor: Colors.purple,
})

const Codes = ({
  array,
  current,
  filter,
  filterHandler,
  clickHandler,
  closeHandler,
}) => {
  const [count, setCount] = useState(array.length)
  return (
    <React.Fragment>
      <div
        className={css({
          display: `flex`,
          justifyContent: `space-between`,
          padding: `0.5rem 0.5rem 0`,
        })}
      >
        <div
          className={css({
            display: `flex`,
          })}
        >
          <div className={css(item, labels, { marginRight: `0.25rem` })}>
            display buffs
          </div>
          <div
            className={css(item, links, filters, {
              color: filter === 'buff' ? Colors.white : Colors.black,
              backgroundColor: filter === 'buff' ? Colors.orange : Colors.white,
            })}
            onClick={() => {
              const filtered = array.filter(k => k.type.includes('buff'))
              setCount(filtered.length)
              filterHandler('buff')
            }}
          >
            General
          </div>
          <div
            className={css(item, links, filters, {
              color: filter === 'boss' ? Colors.white : Colors.black,
              backgroundColor: filter === 'boss' ? Colors.orange : Colors.white,
            })}
            onClick={() => {
              const filtered = array.filter(k => k.type.includes('boss'))
              setCount(filtered.length)
              filterHandler('boss')
            }}
          >
            Bosses
          </div>
          <div
            className={css(item, links, filters, {
              color: filter === 'enemy' ? Colors.white : Colors.black,
              backgroundColor:
                filter === 'enemy' ? Colors.orange : Colors.white,
            })}
            onClick={() => {
              const filtered = array.filter(k => k.type.includes('enemy'))
              setCount(filtered.length)
              filterHandler('enemy')
            }}
          >
            Enemies
          </div>
          <div
            className={css(item, links, filters, {
              color: filter === 'weapon' ? Colors.white : Colors.black,
              backgroundColor:
                filter === 'weapon' ? Colors.orange : Colors.white,
            })}
            onClick={() => {
              const filtered = array.filter(k => k.type.includes('weapon'))
              setCount(filtered.length)
              filterHandler('weapon')
            }}
          >
            Weapons
          </div>
          <div
            className={css(item, links, filters, {
              color: filter === 'skymelt' ? Colors.white : Colors.black,
              backgroundColor:
                filter === 'skymelt' ? Colors.orange : Colors.white,
            })}
            onClick={() => {
              const filtered = array.filter(k => k.type.includes('skymelt'))
              setCount(filtered.length)
              filterHandler('skymelt')
            }}
          >
            Skymelt
          </div>
          <div
            className={css(item, links, filters, {
              color: filter === 'all' ? Colors.white : Colors.black,
              backgroundColor: filter === 'all' ? Colors.orange : Colors.white,
            })}
            onClick={() => {
              const filtered = array.filter(k => k.type.includes('all'))
              setCount(filtered.length)
              filterHandler('all')
            }}
          >
            All
          </div>
          <div className={css(item, labels, { marginRight: `0.25rem` })}>
            {count} codes shown {count > 75 && `– Scroll for more`}
          </div>
        </div>
        <div
          className={css(item, close, { backgroundColor: Colors.purple })}
          onClick={() => closeHandler()}
        >
          Close
        </div>
      </div>
      <div
        className={css({
          width: `100%`,
          padding: `0.25rem`,
          overflowX: `scroll`,
          display: `flex`,
          flexWrap: `wrap`,
          flexDirection: `column`,
        })}
      >
        {array
          .filter(k => k.type.includes(filter))
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
          .map(twitchCode => {
            return (
              <div
                key={twitchCode.id}
                style={{
                  padding: `0.25rem`,
                  width: `20%`,
                }}
              >
                <div
                  onClick={() => clickHandler(twitchCode.id)}
                  className={`${ item } ${ links } ${ current === twitchCode.id &&
                    itemActive }`}
                >
                  <div className={featureLabel}>
                    {current === twitchCode.id ? '–' : '+'} {twitchCode.name}
                  </div>
                </div>
                {current === twitchCode.id && (
                  <div className={twitchCodeDescription}>
                    {twitchCode.description}
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </React.Fragment>
  )
}

export default Codes
