import React from 'react'
import { css } from 'emotion'

const Modal = ({ styles, showing, layoutType, children, ...props }) => {
  return (
    <div
      className={css(
        {
          zIndex: `250`,
          position: `absolute`,
          display: { layoutType },
        },
        styles
      )}
      style={{ display: showing ? layoutType : `none` }}
    >
      {children}
    </div>
  )
}

export default Modal
